import React, { useState, useEffect } from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { visuallyHidden } from '@mui/utils';
import { TextField } from '@mui/material';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import CustomDialog from '../Dialog/CustomDialog';

// 데이터 비교 함수 (정렬)
function descendingComparator(a, b, orderBy) {
  if (typeof a[orderBy] === 'number') {
    return b[orderBy] - a[orderBy];
  } else if (Date.parse(a[orderBy])) {
    return new Date(b[orderBy]) - new Date(a[orderBy]);
  }
  return a[orderBy].localeCompare(b[orderBy]);
}

// 정렬 비교 함수
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// 데이터 정렬 함수
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// 테이블 헤더 컴포넌트
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const headCells = [
    { id: 'title', numeric: false, label: 'Title' },
    { id: 'dataUrl', numeric: false, label: 'Data URL' },
    { id: 'dataDescription', numeric: false, label: 'Description' },
    { id: 'dataType', numeric: false, label: 'Type' },
    { id: 'thumbnail', numeric: false, label: 'Thumbnail' },
    { id: 'views', numeric: true, label: 'Views' },
    { id: 'lastUpdated', numeric: true, label: 'Last Updated' },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
            <TableCell
                key={headCell.id}
                align={headCell.numeric ? 'right' : 'left'}
                padding={headCell.disablePadding ? 'none' : 'normal'}
                sx={{ 
                    paddingLeft: headCell.disablePadding ? 0 : '16px', 
                    paddingRight: '16px',
                    whiteSpace: 'nowrap', // 줄바꿈 방지
                    overflow: 'hidden',   // 넘치는 내용 숨김
                    textOverflow: 'ellipsis', // 넘치는 텍스트에 '...' 표시
                }}
                sortDirection={orderBy === headCell.id ? order : false}
                >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell 
                align={'left'}
                padding={'normal'}
                sx={{ 
                    paddingLeft: '16px', 
                    paddingRight: '16px',
                    whiteSpace: 'nowrap', // 줄바꿈 방지
                    overflow: 'hidden',   // 넘치는 내용 숨김
                    textOverflow: 'ellipsis', // 넘치는 텍스트에 '...' 표시
                }}>Actions</TableCell>
      </TableRow>
    </TableHead>
  );
}

export default function DataEnhancedTable(props) {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('views');
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchParams, setSearchParams] = useSearchParams();
  const [dialogState, setDialogState] = useState(false);
  const [deleteProductId, setDeleteProductId] = useState(null);

  // 데이터 로드
  useEffect(() => {
    fetchProductList();
  }, []);

  useEffect(() => {
    const results = rows.filter((row) =>
      row.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.dataType.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredRows(results);
  }, [searchTerm, rows]);

  const fetchProductList = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_SERVER}/data/list`);
      const products = response.data.map((product) =>
        ({
          id: product.id,
          title: product.title,
          dataUrl: product.dataUrl,
          dataDescription: product.dataDescription,
          dataType: product.dataType,
          thumbnail: product.thumbnail,
          views: product.views,
          lastUpdated: new Intl.DateTimeFormat('ko-KR', {
            dateStyle: 'short',
            timeStyle: 'short',
            timeZone: 'Asia/Seoul',
            hour12: false
          }).format(new Date(product.lastUpdated))
        })
      );
      setRows(products);
      setFilteredRows(products);
    } catch (error) {
      console.error("Error fetching product list:", error);
    }
  };


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const productEdit = (productId) => {
    props.setTabValue("2");
    setSearchParams({ id: `${productId}` });
  };

  const productDelete = async () => {
    if (!deleteProductId) return false;
    try {
      await axios.delete(`${process.env.REACT_APP_BACKEND_SERVER}/data/${deleteProductId}`);
      fetchProductList();
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };

  const handleClickDeleteBtn = (productId) => {
    setDialogState(true);
    setDeleteProductId(productId);
  };

  const confirmDelete = () => {
    setDialogState(false);
    productDelete();
  };

  const cancelDelete = () => {
    setDialogState(false);
    setDeleteProductId(null);
  };
  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TextField
          label="Search Data"
          variant="outlined"
          fullWidth
          value={searchTerm}
          onChange={handleSearchChange}
          sx={{ mb: 2 }}
        />
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={dense ? 'small' : 'medium'}>
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(filteredRows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              ).map((row, index) => (
                <TableRow hover tabIndex={-1} key={row.id}>
                  <TableCell component="th" scope="row" padding="normal">{row.title}</TableCell>
                  <TableCell align="right">{row.dataUrl}</TableCell>
                  <TableCell align="right">{row.dataDescription}</TableCell>
                  <TableCell align="right">{row.dataType}</TableCell>
                  <TableCell align="right">
                    <img src={row.thumbnail} alt="Thumbnail" width="50" height="50" />
                  </TableCell>
                  <TableCell align="right">{row.views}</TableCell>
                  <TableCell align="right">{row.lastUpdated}</TableCell>
                  <TableCell align="right">
                    <IconButton aria-label="edit" color="primary" onClick={() => productEdit(row.id)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton aria-label="delete" color="secondary" onClick={() => handleClickDeleteBtn(row.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
      <CustomDialog Tite={"Delete Confirmation"} text={"Are you sure you want to delete this?"} openDialog={dialogState} confirm={confirmDelete} cancel={cancelDelete}/>
    </Box>
  );
}
