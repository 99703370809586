// src/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#55bfc8',  // primary 색상 설정
      contrastText: '#ffffff',  // primary 버튼의 글씨 색 설정
    },
    secondary: {
      main: '#55bfc8',  // secondary 색상 설정
      contrastText: '#ffffff',  // secondary 버튼의 글씨 색 설정
    },
  },
});

export default theme;
