import React, { useState, useEffect } from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { visuallyHidden } from '@mui/utils';
import { TextField } from '@mui/material';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import CustomDialog from '../Dialog/CustomDialog';

// 데이터 비교 함수 (정렬)
function descendingComparator(a, b, orderBy) {
  if (typeof a[orderBy] === 'number') {
    return b[orderBy] - a[orderBy];
  } else if (Date.parse(a[orderBy])) {
    return new Date(b[orderBy]) - new Date(a[orderBy]);
  }
  return a[orderBy].localeCompare(b[orderBy]);
}

// 정렬 비교 함수
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// 데이터 정렬 함수
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// 테이블 헤더 컴포넌트
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const headCells = [
    { id: 'category', numeric: false, label: 'Category' },
    { id: 'title', numeric: false, label: 'Title' },
    { id: 'content', numeric: false, label: 'Content' },
    { id: 'attachment', numeric: false, label: 'Attachment' },
    { id: 'views', numeric: true, label: 'Views' },
    { id: 'createdAt', numeric: true, label: 'Created At' },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sx={{
              paddingLeft: headCell.disablePadding ? 0 : '16px',
              paddingRight: '16px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell align="right">Actions</TableCell>
      </TableRow>
    </TableHead>
  );
}

export default function PostEnhancedTable(props) {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('views');
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchParams, setSearchParams] = useSearchParams();
  const [dialogState, setDialogState] = useState(false);
  const [deletePostId, setDeletePostId] = useState(null);

  // 데이터 로드
  useEffect(() => {
    fetchPostList();
  }, []);

  useEffect(() => {
    const results = rows.filter((row) =>
      row.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.category.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredRows(results);
  }, [searchTerm, rows]);

  const fetchPostList = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_SERVER}/post/list`);
      const posts = response.data.map((post) => ({
        id: post.id,
        category: post.category,
        title: post.title,
        content: post.content,
        attachment: post.attachment,
        views: post.views,
        createdAt: new Intl.DateTimeFormat('ko-KR', {
          dateStyle: 'short',
          timeStyle: 'short',
          timeZone: 'Asia/Seoul',
          hour12: false,
        }).format(new Date(post.createdAt)),
      }));
      setRows(posts);
      setFilteredRows(posts);
    } catch (error) {
      console.error('Error fetching post list:', error);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const postEdit = (postId) => {
    props.setTabValue('2');
    setSearchParams({ id: `${postId}` });
  };

  const postDelete = async () => {
    if (!deletePostId) return false;
    try {
      await axios.delete(`${process.env.REACT_APP_BACKEND_SERVER}/post/${deletePostId}`);
      fetchPostList();
    } catch (error) {
      console.error('Error deleting post:', error);
    }
  };

  const handleClickDeleteBtn = (postId) => {
    setDialogState(true);
    setDeletePostId(postId);
  };

  const confirmDelete = () => {
    setDialogState(false);
    postDelete();
  };

  const cancelDelete = () => {
    setDialogState(false);
    setDeletePostId(null);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TextField
          label="Search Data"
          variant="outlined"
          fullWidth
          value={searchTerm}
          onChange={handleSearchChange}
          sx={{ mb: 2 }}
        />
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={dense ? 'small' : 'medium'}>
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(filteredRows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              ).map((row, index) => (
                <TableRow hover tabIndex={-1} key={row.id}>
                  <TableCell component="th" scope="row" padding="normal">{row.title}</TableCell>
                  <TableCell align="right">{row.dataUrl}</TableCell>
                  <TableCell align="right">{row.dataDescription}</TableCell>
                  <TableCell align="right">{row.dataType}</TableCell>
                  <TableCell align="right">
                    <img src={row.thumbnail} alt="Thumbnail" width="50" height="50" />
                  </TableCell>
                  <TableCell align="right">{row.views}</TableCell>
                  <TableCell align="right">{row.lastUpdated}</TableCell>
                  <TableCell align="right">
                    <IconButton aria-label="edit" color="primary" onClick={() => postEdit(row.id)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton aria-label="delete" color="secondary" onClick={() => handleClickDeleteBtn(row.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
      <CustomDialog Tite={"Delete Confirmation"} text={"Are you sure you want to delete this?"} openDialog={dialogState} confirm={confirmDelete} cancel={cancelDelete}/>
    </Box>
  );
}
