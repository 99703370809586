import { Link } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';

function Service() {
  return (
    <>
      <div className='ServiceContainer'>
        <div className='cubeInfo'>
          <ul>
            <li>
              <p className='nameWrap'>Service</p>
            </li>
            <li>
              <p className='infoImg'>
                <img src={process.env.PUBLIC_URL + "/img/service1.png"}></img>
              </p>
              <p className='infoTxt'>
                <span>데이터 저작 도구 Data-SLab</span>
                1. 온라인 크라우드소싱 플랫폼<br/>
                2. 인공지능 연구를 위한 트레이닝 데이터 구축
              </p>
            </li>
            <li>
              <p className='infoImg'>
                <img src={process.env.PUBLIC_URL + "/img/service2.png"}></img>
              </p>
              <p className='infoTxt'>
                <span>데이터 마켓플레이스</span>
                1. 데이터 수집, 가공, 분석 및 판매 플랫폼<br/>
                2. 필요 데이터의 효율적 활용을 지원
              </p>
            </li>
            <li>
              <p className='infoImg'>
                <img src={process.env.PUBLIC_URL + "/img/service3.png"}></img>
              </p>
              <p className='infoTxt'>
                <span>데이터 시각화</span>
                1. 기업용 의사결정지원 Intelligence 솔루션<br/>
                2. 헬스케어, 이커머스, 금융, 부동산 분야 적용
              </p>
            </li>
            <li>
              <p className='infoImg'>
                <img src={process.env.PUBLIC_URL + "/img/service4.png"}></img>
              </p>
              <p className='infoTxt'>
                <span>챗봇 서비스</span>
                1. 다주제 대응 멀티디멘션 챗봇 엔진 보유<br/>
                2. 개인비서 서비스, 데이트 챗봇 서비스 론칭 운영
              </p>
            </li>
            <li>
              <p className='infoImg'>
                <img src={process.env.PUBLIC_URL + "/img/service5.png"}></img>
              </p>
              <p className='infoTxt'>
                <span>챗봇 빌더</span>
                1. 자체개발 챗봇 빌더로 다양한 메신저에 적용<br/>
                2. 고객데이터 접수 시나리오를 자유 설정 가능
              </p>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default Service;