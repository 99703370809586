import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Box, Paper, Avatar, Alert } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Header from '../Header';

const AdminLogin = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_SERVER}/auth/login`, { email, password });
            
            // Access token과 Refresh token을 localStorage에 저장
            localStorage.setItem('access_token', response.data.access_token);
            localStorage.setItem('refresh_token', response.data.refresh_token);
            
            console.log('Login successful');
            setErrorMessage(''); // 로그인 성공 시 에러 메시지 초기화
            navigate('/admin');
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                const rawMessage = error.response.data.message;
                const cleanMessage = rawMessage.includes(':') ? rawMessage.split(':').pop().trim() : rawMessage;
                setErrorMessage(cleanMessage);
            } else {
                setErrorMessage('An error occurred during login. Please try again.');
            }
        }
    };

    return (
        <>
            <Header/>
            <Container component="main" maxWidth="xs">
                <Paper elevation={6} sx={{ padding: 3, mt: 20 }}>
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <LockOutlinedIcon sx={{ fill: '#eee'}} />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Sign in
                        </Typography>
                        <Box component="form" onSubmit={handleLogin} sx={{ mt: 3 }}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            {errorMessage && (
                                <Alert severity="error" sx={{ mt: 2 }}>
                                    {errorMessage}
                                </Alert>
                            )}
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                sx={{ mt: 2, mb: 2 }}
                            >
                                Sign In
                            </Button>
                        </Box>
                    </Box>
                </Paper>
            </Container>
        </>
    );
};

export default AdminLogin;
