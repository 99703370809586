import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';

import './css/reset.css'

import Header from './components/Header.js'
import Main from './components/Main.js'
import Visual from './components/Visualization.js'
import Graph from './components/Graph.js'
import Product from './components/Dataproduct.js'
import Notice from './components/Notice.js'
import Footer from './components/Footer.js';
import Admin from './components/Admin/Admin.js';
import AdminLogin from './components/Admin/AdminLogin.js';
import { useEffect } from 'react';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);  // 페이지 이동 시 스크롤을 상단으로 이동
  }, [pathname]);

  return null;  // 실제로는 아무것도 렌더링하지 않음
}

function App() {
  return (
    <ThemeProvider theme={theme}>  {/* ThemeProvider로 전체를 래핑 */}
      <Router>
        <ScrollToTop />  {/* 페이지 이동 시 스크롤 상단으로 */}
        <Header/>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/visual" element={<Visual />} />
          <Route path="/graph" element={<Graph />} />
          <Route path="/product" element={<Product />} />
          <Route path="/notice" element={<Notice />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/admin/login" element={<AdminLogin />} />
        </Routes>
        <Footer/>
      </Router>
    </ThemeProvider>
  );
}

export default App;
