import '../css/Main.css';
import '../css/Experience.css';
import '../css/Service.css';
import '../css/Conect.css';
import Bnrbusiness from './Bnrbusiness.js';
import Experience from './Experience.js';
import Service from './Service.js';
import Conect from './Conect.js';
import { Link } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';

function Main() {
  return (
    <div id='main'>
      <Bnrbusiness/>
      <Experience/>
      <Service/>
      <Conect/>
    </div>
  )
}

export default Main;