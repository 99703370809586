import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  TextField,
  Button,
  Grid,
  InputLabel,
  Typography,
  Box,
  Snackbar,
  Alert,IconButton
} from '@mui/material';
import { styled } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';

const DataUploadForm = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id'); // 쿼리 파라미터에서 id를 읽어옴
  const navigate = useNavigate();
  
  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const [dataDetails, setDataDetails] = useState({
    title: '',
    dataUrl: '',
    dataDescription: '',
    dataType: '',
    thumbnail: '',
    views: 0,
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (id) {
      setIsEditing(true);
      const fetchData = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_BACKEND_SERVER}/data/${id}`);
          const dataItem = response.data;
          setDataDetails({
            title: dataItem.title,
            dataUrl: dataItem.dataUrl,
            dataDescription: dataItem.dataDescription || '',
            dataType: dataItem.dataType,
            thumbnail: dataItem.thumbnail || '',
            views: dataItem.views,
          });
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      fetchData();
    }
  }, [id]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setDataDetails({
      ...dataDetails,
      [name]: value,
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    
    reader.onloadend = () => {
      setDataDetails({
        ...dataDetails,
        thumbnail: reader.result,
      });
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const dataToSend = {
        title: dataDetails.title,
        dataUrl: dataDetails.dataUrl,
        dataDescription: dataDetails.dataDescription,
        dataType: dataDetails.dataType,
        thumbnail: dataDetails.thumbnail,
      };

      if (isEditing) {
        await axios.patch(`${process.env.REACT_APP_BACKEND_SERVER}/data/${id}`, dataToSend);
      } else {
        await axios.post(`${process.env.REACT_APP_BACKEND_SERVER}/data/create`, dataToSend);
      }

      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error uploading data:', error);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    window.location.href = '/admin';
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
      <Typography variant="h4" gutterBottom>
        {isEditing ? 'Edit Data' : 'Upload New Data'}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id="title"
            label="Title"
            name="title"
            value={dataDetails.title}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id="dataUrl"
            label="Data URL"
            name="dataUrl"
            value={dataDetails.dataUrl}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            id="dataDescription"
            label="Data Description"
            name="dataDescription"
            multiline
            rows={4}
            value={dataDetails.dataDescription}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id="dataType"
            label="Data Type"
            name="dataType"
            value={dataDetails.dataType}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <InputLabel id="thumbnail-label">Thumbnail</InputLabel>
          <Button
            component="label"
            role={undefined}
            variant="contained"
            sx={{ mt: 1, mb: 2 }}
          >
            Upload Thumbnail
            <VisuallyHiddenInput
              type="file"
              accept="image/*"
              onChange={handleFileChange}
            />
          </Button>
          {dataDetails.thumbnail && (
            <Box sx={{ mt: 2 }}>
              <img src={dataDetails.thumbnail} alt="Thumbnail" width="100" />
              <IconButton
                onClick={() => setDataDetails({ ...dataDetails, thumbnail: '' })}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          )}
        </Grid>

        <Grid item xs={12}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
          >
            {isEditing ? 'Save Changes' : 'Upload Data'}
          </Button>
        </Grid>
      </Grid>

      <Snackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: '100%' }}
        >
          {isEditing ? 'Data updated successfully!' : 'Data uploaded successfully!'}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default DataUploadForm;
