
import {
    Button,Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@mui/material';




const CustomDialog = ({title,text,openDialog, confirm,cancel, isQuestion = true}) =>{
    return(
        <Dialog open={openDialog} onClose={cancel}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
            <DialogContentText>
                {text}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            {isQuestion ? 
                <Button onClick={cancel} color="primary">
                    Cancel
                </Button>
            
            :   <></>}
            <Button onClick={confirm} color="primary" autoFocus>
                Delete
            </Button>
        </DialogActions>
        </Dialog>
    )
}

export default CustomDialog;