import { Link, useNavigate } from "react-router-dom";
import '../../css/Admin.css'
import React, { useEffect, useState } from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import NotesOutlinedIcon from '@mui/icons-material/NotesOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import Header from "../Header";
import apiClient from "../../apiClient";
import DataPage from "./Page/DataPage";
import PostPage from "./Page/PostPage";

function Admin() {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        const checkAuth = async () => {
            const accessToken = localStorage.getItem('access_token');
            if (!accessToken) {
                // 토큰이 없으면 로그인 페이지로 리다이렉트
                navigate('/admin/login');
                return;
            }

            try {
                // 서버에 토큰 유효성 확인 요청
                await apiClient.get('/auth/validate-token', {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
            } catch (error) {
                // 토큰이 유효하지 않으면 로그인 페이지로 리다이렉트
                console.error('Token validation failed:', error);
                localStorage.removeItem('access_token');
                localStorage.removeItem('refresh_token');
                navigate('/admin/login');
            }
        };

        checkAuth();
    }, [navigate]);

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
    };

    return (
        <div className="Admin">
            <div className="wrap">
                <div className="left_div">
                    <div className="menu_list_div">
                        <h4>관리자 페이지</h4>
                        <List component="nav" aria-label="main mailbox folders" className="leftMenu">
                            <ListItemButton
                                selected={selectedIndex === 0}
                                onClick={(event) => handleListItemClick(event, 0)}
                            >
                                <ListItemIcon>
                                    <BarChartOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText primary="데이터 관리" />
                            </ListItemButton>
                            <ListItemButton
                                selected={selectedIndex === 1}
                                onClick={(event) => handleListItemClick(event, 1)}
                            >
                                <ListItemIcon>
                                    <NotesOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText primary="게시글 관리" />
                            </ListItemButton>
                        </List>
                    </div>
                </div>
                <div className="right_div">
                    {selectedIndex === 0 ? <DataPage/>: null}
                    {selectedIndex === 1 ? <PostPage/> : null}
                </div>
            </div>
        </div>
    );
}

export default Admin;
