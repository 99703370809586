import '../css/Notice.css';
import { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';

function Notice() {

  const categories = [
    '전체 검색', 
    'FAQ', 
    'Q&A', 
  ];

  // 공지사항 데이터 (예시로 20개의 데이터)
  const notices = [
    { id: 1, field: '안내', title: '[공지사항] 개인정보 처리방침 변경안내', file: '파일', inquiry: 152, date: '2017.07.13' },
    { id: 2, field: '안내', title: '공지사항 안내입니다. 이용해주셔서 감사합니다', file: '파일', inquiry: 152, date: '2017.06.15' },
    { id: 3, field: '안내', title: '공지사항 안내입니다. 이용해주셔서 감사합니다', file: '파일', inquiry: 152, date: '2017.06.15' },
    { id: 4, field: '안내', title: '[공지사항] 개인정보 처리방침 변경안내', file: '파일', inquiry: 152, date: '2017.07.13' },
    { id: 5, field: '안내', title: '공지사항 안내입니다. 이용해주셔서 감사합니다', file: '파일', inquiry: 152, date: '2017.06.15' },
    { id: 6, field: '안내', title: '공지사항 안내입니다. 이용해주셔서 감사합니다', file: '파일', inquiry: 152, date: '2017.06.15' },
    { id: 7, field: '안내', title: '[공지사항] 개인정보 처리방침 변경안내', file: '파일', inquiry: 152, date: '2017.07.13' },
    { id: 8, field: '안내', title: '공지사항 안내입니다. 이용해주셔서 감사합니다', file: '파일', inquiry: 152, date: '2017.06.15' },
    { id: 9, field: '안내', title: '공지사항 안내입니다. 이용해주셔서 감사합니다', file: '파일', inquiry: 152, date: '2017.06.15' },
    { id: 10, field: '안내', title: '[공지사항] 개인정보 처리방침 변경안내', file: '파일', inquiry: 152, date: '2017.07.13' },
    { id: 11, field: '안내', title: '[공지사항] 개인정보 처리방침 변경안내', file: '파일', inquiry: 152, date: '2017.07.13' },
    { id: 12, field: '안내', title: '[공지사항] 개인정보 처리방침 변경안내', file: '파일', inquiry: 152, date: '2017.07.13' },
    { id: 13, field: '안내', title: '[공지사항] 개인정보 처리방침 변경안내', file: '파일', inquiry: 152, date: '2017.07.13' },
    { id: 14, field: '안내', title: '[공지사항] 개인정보 처리방침 변경안내', file: '파일', inquiry: 152, date: '2017.07.13' },
    { id: 15, field: '안내', title: '[공지사항] 개인정보 처리방침 변경안내', file: '파일', inquiry: 152, date: '2017.07.13' },
    { id: 16, field: '안내', title: '공지사항 안내입니다. 이용해주셔서 감사합니다', file: '파일', inquiry: 152, date: '2017.06.15' },
    { id: 17, field: '안내', title: '공지사항 안내입니다. 이용해주셔서 감사합니다', file: '파일', inquiry: 152, date: '2017.06.15' },
    { id: 18, field: '안내', title: '[공지사항] 개인정보 처리방침 변경안내', file: '파일', inquiry: 152, date: '2017.07.13' },
    { id: 19, field: '안내', title: '공지사항 안내입니다. 이용해주셔서 감사합니다', file: '파일', inquiry: 152, date: '2017.06.15' },
    { id: 20, field: '안내', title: '공지사항 안내입니다. 이용해주셔서 감사합니다', file: '파일', inquiry: 200, date: '2021.06.01' },
  ];

  const [selectedCategory, setSelectedCategory] = useState('카테고리');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1); // 현재 페이지 상태
  const noticesPerPage = 10; // 페이지 당 공지사항 개수
  const dropdownRef = useRef(null);

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setIsDropdownOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // 페이지네이션에 필요한 공지사항 필터링
  const indexOfLastNotice = currentPage * noticesPerPage;
  const indexOfFirstNotice = indexOfLastNotice - noticesPerPage;
  const currentNotices = notices.slice(indexOfFirstNotice, indexOfLastNotice);

  // 페이지 변경 핸들러
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // 페이지 번호 생성
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(notices.length / noticesPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div id='notice'>
      <h2>데이터 상품</h2>
      <div className='categoryWrap'>
        <div className='customSelect' onClick={() => setIsDropdownOpen(!isDropdownOpen)} ref={dropdownRef}>
          <div className='selectedOption' style={{ color: selectedCategory === '카테고리' ? '#aaa' : '#222' }}>
            {selectedCategory}
          </div>
          {isDropdownOpen && (
            <ul className='dropdown'>
              {categories.map((category, index) => (
                <li key={index} onClick={() => handleCategorySelect(category)}>
                  {category}
                </li>
              ))}
            </ul>
          )}
        <img className='dropdownImg' src={process.env.PUBLIC_URL + "/img/dropdown.png"} alt="search" />
        </div>
        <div className="searchWrap">
          <input type="text" placeholder="검색어를 입력하세요." />
          <button type='button'>
            <img className='searchBtn' src={process.env.PUBLIC_URL + "/img/searchG.png"} alt="search" />
          </button>
        </div>
      </div>
      <div id="board-list">
        <div className="container">
          <table className="board-table">
            <thead>
              <tr>
                <th scope="col" className="th-num">번호</th>
                <th scope="col" className="th-field">분야</th>
                <th scope="col" className="th-title">제목</th>
                <th scope="col" className="th-file">첨부파일</th>
                <th scope="col" className="th-inquiry">조회수</th>
                <th scope="col" className="th-date">작성일</th>
              </tr>
            </thead>
            <tbody>
              {currentNotices.map((notice) => (
                <tr key={notice.id}>
                  <td>{notice.id}</td>
                  <td>{notice.field}</td>
                  <td className='title'><Link to={''}>{notice.title}</Link></td>
                  <td>{notice.file}</td>
                  <td>{notice.inquiry}</td>
                  <td>{notice.date}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className='page'>
        <p className='prev' onClick={() => currentPage > 1 && handlePageChange(currentPage - 1)}>&lt;</p>
        <ul className='pagination'>
          {pageNumbers.map(number => (
            <li key={number} className={currentPage === number ? 'active' : ''} onClick={() => handlePageChange(number)}>
              {number}
            </li>
          ))}
        </ul>
        <p className='next' onClick={() => currentPage < pageNumbers.length && handlePageChange(currentPage + 1)}>&gt;</p>
      </div>
    </div>
  );
}

export default Notice;
