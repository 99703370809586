import { Link } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';

function Bnrbusiness() {
  return (
    <>
      <div className='mainBnr'>
        <h2>Digital<br/>Transformation Innovator</h2>
        <p>스타트업 기업의 데이터기반 서비스 구축을 지원하고<br/>
          사업화를 도와 시장에서 매출과 성공을 이루도록 지원하고 있습니다.<br/>
          귀사의 새로운 성장동력을 만들어 갈 수 있도록<br/>
          저희의 데이터 사업 전문가들이 최선을 다하겠습니다.
        </p>
        <div className='actionWrap'>
          <ul className='keyWrap'>
            <li><Link to={''}><img src={process.env.PUBLIC_URL + "/img/B5.png"}></img></Link></li>
            <li><Link to={''}><img src={process.env.PUBLIC_URL + "/img/B5.png"}></img></Link></li>
            <li><Link to={''}><img src={process.env.PUBLIC_URL + "/img/B5.png"}></img></Link></li>
            <li><Link to={''}><img src={process.env.PUBLIC_URL + "/img/B5.png"}></img></Link></li>
            <li><Link to={''}><img src={process.env.PUBLIC_URL + "/img/B5.png"}></img></Link></li>
            <li><Link to={''}><img src={process.env.PUBLIC_URL + "/img/B5.png"}></img></Link></li>
            <li><Link to={''}><img src={process.env.PUBLIC_URL + "/img/B5.png"}></img></Link></li>
          </ul>
          <ul className='infoWrap'>
            <li>
              <p className='dataTxt'>데이터 수</p>
              <p className='dataNum colorNum'>1,440</p>
            </li>
            <li>
              <p className='dataTxt'>다운로드 수</p>
              <p className='dataNum'>1,440</p>
            </li>
          </ul>
        </div>
      </div>
      <ul className='liveWrap'>
        <li>
          <p>카테고리별 현황<button type='button'>더보기 +</button></p>
          <div>
            <ul>
              <li className='active'>추천순</li>
              <li>최신순</li>
              <li>조회순</li>
            </ul>
            <p><img src={process.env.PUBLIC_URL + "/img/liveCategory.png"}></img></p>
          </div>
        </li>
        <li>
          <p>지역별 현황<button type='button'>더보기 +</button></p>
          <div>
            <p><img src={process.env.PUBLIC_URL + "/img/liveMap.png"}></img></p>
          </div>
        </li>
      </ul>
      <div className='businessContainer'>
        <h3>Business</h3>
        <p>스타트업랩은 데이터 간 연결과 가치화를 통하여 고객사가 데이터 활용 생태계로 진입하도록 이끌어갑니다.</p>
        <ul>
          <li>
            <img src={process.env.PUBLIC_URL + "/img/B1.png"}></img>
            <span>데이터 구축·가공</span>고객사의 BM에 맞춘 정보구조와 데이터를 설계하고 구축·가공을 진행
          </li>
          <li>
            <img src={process.env.PUBLIC_URL + "/img/B2.png"}></img>
            <span>데이터 활용·사업화</span>데이터를 활용하여 
            BM의 강화와 확장으로 연결
          </li>
          <li>
            <img src={process.env.PUBLIC_URL + "/img/B3.png"}></img>
            <span>데이터 활용·사업화</span>다양한 서비스 개발 경험과 기술을 
            통해 가장 효율적인 로드맵을 제시
          </li>
          <li>
            <img src={process.env.PUBLIC_URL + "/img/B4.png"}></img>
            <span>데이터 분석·시각화</span>기업의 의사결정, 공공정책의 판단 근거로
            활용할 수 있도록 데이터 분석 및 시각화
          </li>
          <li>
            <img src={process.env.PUBLIC_URL + "/img/B5.png"}></img>
            <span>데이터 크라우드소싱</span>AI어시스턴트가 돕는 당사의 저작 도구 'Data-SLap', 충분한 경험을 지닌 데이터 구축 담당자들로 고품질의 데이터를 생성
          </li>
        </ul>
        <div className='cubeInfo'>
          <p>데이터 컨설팅, 수집, 가공</p>
          <ul>
            <li>
              <p className='infoImg'>
                <img src={process.env.PUBLIC_URL + "/img/infoImg1.png"}></img>
              </p>
              <p className='infoTxt'>
                <span>문화 데이터</span>
                스페이셜코어는 기관, 기업의 수많은 문화 데이터를 광범위하고 빠르게 구축하고 연결해 왔습니다. 
문화 데이터는 다양한 분야와 연관되는 특성을 지닌 한편 비정형적 경향성이 높아 수집, 구축, 
관리의 충분한 경험이 필요합니다.
              </p>
            </li>
            <li>
              <p className='infoImg'>
                <img src={process.env.PUBLIC_URL + "/img/infoImg2.png"}></img>
              </p>
              <p className='infoTxt'>
                <span>예술 데이터</span>
                국내 예술 데이터는 전반적으로 파편화되어 있습니다. 수집과 동질적 카테고리 구분이 어려운 예술 분야의 
데이터를 효율적으로 모으고 관계성을 부여할 수 
있도록 각 기관, 기업, 단체의 컨설팅 해 왔습니다.
              </p>
            </li>
            <li>
              <p className='infoImg'>
                <img src={process.env.PUBLIC_URL + "/img/infoImg3.png"}></img>
              </p>
              <p className='infoTxt'>
                <span>관광 데이터</span>
                애프터 코로나 시대를 준비하는 관광활성화의 
중심에도 데이터 기반 의사결정이 매우 중요합니다, 한국관광공사와 공공기관, 지자체 자문을 해오며 
스마트관광거리 데이터 설계, 한류데이터 구축 등 
다양한 인/아웃바운드 사업에서 고품질의 
데이터를 만들 수 있도록 노력합니다.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default Bnrbusiness;