import '../css/Graph.css';
import GraphList from './GraphList.js';
import { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Main from './Main';

function Graph() {

  return (
    <div id='graph'>
      <div className='graphWrap'>
        <h2>건물 정보 보유 데이터 수</h2>
        <div className='dataWrap'>
          <p>피타그래프</p>
          <p>최근갱신일 <span>2024-10-15 15:33</span></p>
          <p>조회수 <span>15</span></p>
        </div>
        <div className='categoryWrap'>
          <p><img src={process.env.PUBLIC_URL + "/img/circleMint.png"}/>전국</p>
          <p><img src={process.env.PUBLIC_URL + "/img/circleMint.png"}/>건축</p>
        </div>
        <GraphList/>
      </div>
      <div className="cellContainer">
        <ul>
          <li>
            <p className='cellTitle'>생성날짜</p>
            <p className='cellInfo'>2024-09-05</p>
          </li>
          <li>
            <p className='cellTitle'>최근 갱신일</p>
            <p className='cellInfo'>2024-10-30</p>
          </li>
          <li>
            <p className='cellTitle'>유형</p>
            <p className='cellInfo'>csv</p>
          </li>
          <li>
            <p className='cellTitle'>데이터 가공유형</p>
            <p className='cellInfo'>가공</p>
          </li>
          <li>
            <p className='cellTitle'>키워드</p>
            <p className='cellInfo'>건물, 대한민국, 건축, 관광</p>
          </li>
          <li>
            <p className='cellTitle'>카테고리</p>
            <p className='cellInfo'>공간정보</p>
          </li>
          <li>
            <p className='cellTitle'>내용</p>
            <p className='cellInfo'>
            본 데이터셋은 다양한 건물과 장소에 대한 위치 정보 및 범주 정보를 제공하며, 도시 개발 및 관리, 위치 기반 서비스, 지도 제작 등의 다양한 활용 가능성을 염두에 두고 구축되었습니다. 특히, 이 데이터셋은 특정 건물 및 도로와 관련된 주요 정보를 포함하여, 각 지역별로 쉽게 식별하고 접근할 수 있도록 설계되었습니다.
            <br></br>
            <br></br>
            CSV 설명 <br></br>
            - 인코딩 : EUC-KR<br></br>
            - 구분자 : ,<br></br>
            - QUOTE 문자 : "
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Graph;
