import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import * as React from 'react';
import DataUploadForm from '../Form/DataUploadForm';
import DataEnhancedTable from '../Table/DataEnhancedTable';


function DataPage(props){
    const [tabValue, setTabValue] = React.useState('1');
  
    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return(
        <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={tabValue}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} aria-label="lab API tabs example">
                    <Tab label="데이터 목록" value="1" />
                    <Tab label="데이터 등록" value="2" />
                </TabList>
                </Box>
                <TabPanel value="1">
                    <h4>데이터 목록</h4>
                    <DataEnhancedTable setTabValue={setTabValue}/>
                </TabPanel>
                <TabPanel value="2">
                    <h4>데이터 등록</h4>
                    <DataUploadForm/>
                </TabPanel>
            </TabContext>
        </Box>
    )
    
}

export default DataPage;