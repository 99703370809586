import '../css/Dataproduct.css';
import { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';

function Dataproduct() {

  return (
    <div id='product'>
      <h2>데이터 상품</h2>
      <div className='actionWrap'>
        <div className="searchWrap">
          <input type="text"/>
          <span>
            <img src={process.env.PUBLIC_URL + "/img/search43.png"} />
          </span>
        </div>
        <div className='categoryWrap'>
          <p className='categoryTitle'>데이터 마켓 상세검색</p>
          <ul className='categoryList'>
            <li>
              <p className='listTitle'>&middot; 카테고리</p>
              <ul className='listContent'>
                <li className='active'>전체</li>
                <li>문화예술</li>
                <li>문화유산</li>
                <li>문화산업</li>
                <li>관광</li>
              </ul>
            </li>
            <li>
              <p className='listTitle'>&middot; 추천테마</p>
              <ul className='listContent'>
                <li className='active'>전체</li>
                <li>한류</li>
                <li>MZ</li>
                <li>미디어</li>
                <li>여행</li>
                <li>로컬</li>
              </ul>
            </li>
            <li>
              <p className='listTitle'>&middot; 제공기관별</p>
              <ul className='listContent'>
                <li className='active'>전체</li>
                <li>국립중앙도서관</li>
                <li>국민체육진흥공단</li>
                <li>데이터마케팅코리아</li>
                <li>부산정보산업진흥원</li>
                <li>서울예술의전당</li>
                <li>아이지에이웍스</li>
                <li>야놀자</li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <div className='displayWrap'>
        <ul className='sequence'>
          <li className='active'>추천순</li>
          <li>최신순</li>
          <li>조회순</li>
          <li>다운로드순</li>
          <li>낮은 가격순</li>
          <li>높은 가격순</li>
        </ul>
        <div className='cubeInfo'>
          <ul>
            <li>
              <p className='infoImg'>
                <img src={process.env.PUBLIC_URL + "/img/categoryname.png"}></img>
              </p>
              <div className='textWrap'>
                <ul className='titleTxt'>
                  <li className='title'>창업진흥원</li>
                  <li>여행/관광/JSON/CSV/협의</li>
                  <li>유형 | JSON, CSV</li>
                  <li>가격 | 협의</li>
                  <li>데이터 갱신 주기 | Yearly</li>
                </ul>
                <p className='infoTxt'>
                (소개글 요약 버전)<br/>
                &nbsp;&nbsp;&middot; 데이터 소개 - 온라인(PC 모바일) 소비자 서베이 데이터. <br/>
                &nbsp;&nbsp;&middot; 여행자와 현지인이 지각하는 14개 속성의 지역 이미지 (예: 남성적,) 
                </p>
              </div>
            </li>
            <li>
              <p className='infoImg'>
                <img src={process.env.PUBLIC_URL + "/img/categoryname.png"}></img>
              </p>
              <div className='textWrap'>
                <ul className='titleTxt'>
                  <li className='title'>창업진흥원</li>
                  <li>여행/관광/JSON/CSV/협의</li>
                  <li>유형 | JSON, CSV</li>
                  <li>가격 | 협의</li>
                  <li>데이터 갱신 주기 | Yearly</li>
                </ul>
                <p className='infoTxt'>
                (소개글 요약 버전)<br/>
                &nbsp;&nbsp;&middot; 데이터 소개 - 온라인(PC 모바일) 소비자 서베이 데이터. <br/>
                &nbsp;&nbsp;&middot; 여행자와 현지인이 지각하는 14개 속성의 지역 이미지 (예: 남성적,) 
                </p>
              </div>
            </li>
            <li>
              <p className='infoImg'>
                <img src={process.env.PUBLIC_URL + "/img/categoryname.png"}></img>
              </p>
              <div className='textWrap'>
                <ul className='titleTxt'>
                  <li className='title'>창업진흥원</li>
                  <li>여행/관광/JSON/CSV/협의</li>
                  <li>유형 | JSON, CSV</li>
                  <li>가격 | 협의</li>
                  <li>데이터 갱신 주기 | Yearly</li>
                </ul>
                <p className='infoTxt'>
                (소개글 요약 버전)<br/>
                &nbsp;&nbsp;&middot; 데이터 소개 - 온라인(PC 모바일) 소비자 서베이 데이터. <br/>
                &nbsp;&nbsp;&middot; 여행자와 현지인이 지각하는 14개 속성의 지역 이미지 (예: 남성적,) 
                </p>
              </div>
            </li>
            <li>
              <p className='infoImg'>
                <img src={process.env.PUBLIC_URL + "/img/categoryname.png"}></img>
              </p>
              <div className='textWrap'>
                <ul className='titleTxt'>
                  <li className='title'>창업진흥원</li>
                  <li>여행/관광/JSON/CSV/협의</li>
                  <li>유형 | JSON, CSV</li>
                  <li>가격 | 협의</li>
                  <li>데이터 갱신 주기 | Yearly</li>
                </ul>
                <p className='infoTxt'>
                (소개글 요약 버전)<br/>
                &nbsp;&nbsp;&middot; 데이터 소개 - 온라인(PC 모바일) 소비자 서베이 데이터. <br/>
                &nbsp;&nbsp;&middot; 여행자와 현지인이 지각하는 14개 속성의 지역 이미지 (예: 남성적,) 
                </p>
              </div>
            </li>
            <li>
              <p className='infoImg'>
                <img src={process.env.PUBLIC_URL + "/img/categoryname.png"}></img>
              </p>
              <div className='textWrap'>
                <ul className='titleTxt'>
                  <li className='title'>창업진흥원</li>
                  <li>여행/관광/JSON/CSV/협의</li>
                  <li>유형 | JSON, CSV</li>
                  <li>가격 | 협의</li>
                  <li>데이터 갱신 주기 | Yearly</li>
                </ul>
                <p className='infoTxt'>
                (소개글 요약 버전)<br/>
                &nbsp;&nbsp;&middot; 데이터 소개 - 온라인(PC 모바일) 소비자 서베이 데이터. <br/>
                &nbsp;&nbsp;&middot; 여행자와 현지인이 지각하는 14개 속성의 지역 이미지 (예: 남성적,) 
                </p>
              </div>
            </li>
            <li>
              <p className='infoImg'>
                <img src={process.env.PUBLIC_URL + "/img/categoryname.png"}></img>
              </p>
              <div className='textWrap'>
                <ul className='titleTxt'>
                  <li className='title'>창업진흥원</li>
                  <li>여행/관광/JSON/CSV/협의</li>
                  <li>유형 | JSON, CSV</li>
                  <li>가격 | 협의</li>
                  <li>데이터 갱신 주기 | Yearly</li>
                </ul>
                <p className='infoTxt'>
                (소개글 요약 버전)<br/>
                &nbsp;&nbsp;&middot; 데이터 소개 - 온라인(PC 모바일) 소비자 서베이 데이터. <br/>
                &nbsp;&nbsp;&middot; 여행자와 현지인이 지각하는 14개 속성의 지역 이미지 (예: 남성적,) 
                </p>
              </div>
            </li>
            <li>
              <p className='infoImg'>
                <img src={process.env.PUBLIC_URL + "/img/categoryname.png"}></img>
              </p>
              <div className='textWrap'>
                <ul className='titleTxt'>
                  <li className='title'>창업진흥원</li>
                  <li>여행/관광/JSON/CSV/협의</li>
                  <li>유형 | JSON, CSV</li>
                  <li>가격 | 협의</li>
                  <li>데이터 갱신 주기 | Yearly</li>
                </ul>
                <p className='infoTxt'>
                (소개글 요약 버전)<br/>
                &nbsp;&nbsp;&middot; 데이터 소개 - 온라인(PC 모바일) 소비자 서베이 데이터. <br/>
                &nbsp;&nbsp;&middot; 여행자와 현지인이 지각하는 14개 속성의 지역 이미지 (예: 남성적,) 
                </p>
              </div>
            </li>
            <li>
              <p className='infoImg'>
                <img src={process.env.PUBLIC_URL + "/img/categoryname.png"}></img>
              </p>
              <div className='textWrap'>
                <ul className='titleTxt'>
                  <li className='title'>창업진흥원</li>
                  <li>여행/관광/JSON/CSV/협의</li>
                  <li>유형 | JSON, CSV</li>
                  <li>가격 | 협의</li>
                  <li>데이터 갱신 주기 | Yearly</li>
                </ul>
                <p className='infoTxt'>
                (소개글 요약 버전)<br/>
                &nbsp;&nbsp;&middot; 데이터 소개 - 온라인(PC 모바일) 소비자 서베이 데이터. <br/>
                &nbsp;&nbsp;&middot; 여행자와 현지인이 지각하는 14개 속성의 지역 이미지 (예: 남성적,) 
                </p>
              </div>
            </li>
            <li>
              <p className='infoImg'>
                <img src={process.env.PUBLIC_URL + "/img/categoryname.png"}></img>
              </p>
              <div className='textWrap'>
                <ul className='titleTxt'>
                  <li className='title'>창업진흥원</li>
                  <li>여행/관광/JSON/CSV/협의</li>
                  <li>유형 | JSON, CSV</li>
                  <li>가격 | 협의</li>
                  <li>데이터 갱신 주기 | Yearly</li>
                </ul>
                <p className='infoTxt'>
                (소개글 요약 버전)<br/>
                &nbsp;&nbsp;&middot; 데이터 소개 - 온라인(PC 모바일) 소비자 서베이 데이터. <br/>
                &nbsp;&nbsp;&middot; 여행자와 현지인이 지각하는 14개 속성의 지역 이미지 (예: 남성적,) 
                </p>
              </div>
            </li>
            <li>
              <p className='infoImg'>
                <img src={process.env.PUBLIC_URL + "/img/categoryname.png"}></img>
              </p>
              <div className='textWrap'>
                <ul className='titleTxt'>
                  <li className='title'>창업진흥원</li>
                  <li>여행/관광/JSON/CSV/협의</li>
                  <li>유형 | JSON, CSV</li>
                  <li>가격 | 협의</li>
                  <li>데이터 갱신 주기 | Yearly</li>
                </ul>
                <p className='infoTxt'>
                (소개글 요약 버전)<br/>
                &nbsp;&nbsp;&middot; 데이터 소개 - 온라인(PC 모바일) 소비자 서베이 데이터. <br/>
                &nbsp;&nbsp;&middot; 여행자와 현지인이 지각하는 14개 속성의 지역 이미지 (예: 남성적,) 
                </p>
              </div>
            </li>
            <li>
              <p className='infoImg'>
                <img src={process.env.PUBLIC_URL + "/img/categoryname.png"}></img>
              </p>
              <div className='textWrap'>
                <ul className='titleTxt'>
                  <li className='title'>창업진흥원</li>
                  <li>여행/관광/JSON/CSV/협의</li>
                  <li>유형 | JSON, CSV</li>
                  <li>가격 | 협의</li>
                  <li>데이터 갱신 주기 | Yearly</li>
                </ul>
                <p className='infoTxt'>
                (소개글 요약 버전)<br/>
                &nbsp;&nbsp;&middot; 데이터 소개 - 온라인(PC 모바일) 소비자 서베이 데이터. <br/>
                &nbsp;&nbsp;&middot; 여행자와 현지인이 지각하는 14개 속성의 지역 이미지 (예: 남성적,) 
                </p>
              </div>
            </li>
            <li>
              <p className='infoImg'>
                <img src={process.env.PUBLIC_URL + "/img/categoryname.png"}></img>
              </p>
              <div className='textWrap'>
                <ul className='titleTxt'>
                  <li className='title'>창업진흥원</li>
                  <li>여행/관광/JSON/CSV/협의</li>
                  <li>유형 | JSON, CSV</li>
                  <li>가격 | 협의</li>
                  <li>데이터 갱신 주기 | Yearly</li>
                </ul>
                <p className='infoTxt'>
                (소개글 요약 버전)<br/>
                &nbsp;&nbsp;&middot; 데이터 소개 - 온라인(PC 모바일) 소비자 서베이 데이터. <br/>
                &nbsp;&nbsp;&middot; 여행자와 현지인이 지각하는 14개 속성의 지역 이미지 (예: 남성적,) 
                </p>
              </div>
            </li>
          </ul>
          <div className='page'>
            <p className='prev'>&lt;</p>
            <ul className='pagination'>
              <li>1</li>
              <li>2</li>
              <li>3</li>
              <li>4</li>
              <li>5</li>
              <li>6</li>
            </ul>
            <p className='prev'>&gt;</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dataproduct;
