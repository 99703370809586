import { Link } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';

function Conect() {
  return (
    <div className='conect'>
      <div className='sendWrap'>
        <div className="infoWrap">
          <div className="top">
            <p className='firstTxt'>최고의 데이터 파트너가 되겠습니다.</p>
            <p className='secondTxt'>스타트업랩은 데이터 관련 지원사업, 컨설팅,
데이터 구축 / 가공 / 분석 / 시각화 / 현행화 / 유통 등
데이터의 모든 것을 다룹니다.<br/>
궁금하신 부분, 함께하실 부분 편하게 문의주세요.</p>
          </div>
          <div className="bottom">
            <input type='checkbox'></input>
            <p>개인정보 수집 및 이용 동의<button type='button'>약관보기</button></p>
          </div>
        </div>
        <div className="inputWrap">
            <ul>
              <li>
                <p className="title">회사명<span className='redStar'>*</span></p>
                <p className="warning_txt"></p>
                <p className='writWrap'>
                  <input type="text" id="company" placeholder="회사명을 입력해주세요."></input>
                </p>
              </li>
              <li>
                <p className="title">Name<span className='redStar'>*</span></p>
                <p className="warning_txt"></p>
                <p className='writWrap'>
                  <input type="text" id="name" placeholder="이름을 입력해주세요."></input>
                </p>
              </li>
              <li>
                <p className="title">Email<span className='redStar'>*</span></p>
                <p className="warning_txt"></p>
                <p className='writWrap'>
                  <input type="text" id="email" placeholder="이메일을 입력해주세요."></input>
                </p>
              </li>
              <li>
                <p className="title">Phone number</p>
                <p className="warning_txt"></p>
                <p className='writWrap'>
                  <input type="text" id="number" placeholder="전화번호를 입력해주세요."></input>
                </p>
              </li>
              <li>
                <p className="title">Subject</p>
                <p className="warning_txt"></p>
                <p className='writWrap'>
                  <input type="text" id="title" placeholder="제목을 입력해주세요."></input>
                </p>
              </li>
              <li>
                <p className="title">Note</p>
                <p className="warning_txt"></p>
                <p className='writWrap'>
                  <textarea id="note" placeholder="문의하고자 하는 내용을 입력해주세요."></textarea>
                </p>
              </li>
            </ul>
            <p className='sendBtn'>Send Message</p>
        </div>
        </div>
      <div className='mapWrap'>
        <ul className='addressWrap'>
          <li>
            <p className='icon'>
              <img src={process.env.PUBLIC_URL + "/img/phoneicon.png"}></img>
            </p>
            <p className='adTxt'>
              전화번호
              <span>02-0000-0000</span>
            </p>
          </li>
          <li>
            <p className='icon'>
              <img src={process.env.PUBLIC_URL + "/img/mailicon.png"}></img>
            </p>
            <p className='adTxt'>
              이메일
              <span>info@email.com</span>
            </p>
          </li>
          <li>
            <p className='icon'>
              <img src={process.env.PUBLIC_URL + "/img/mapicon.png"}></img>
            </p>
            <p className='adTxt'>
              주소
              <span>서울특별시 광진구 광나루로 478, 광진경제허브 406</span>
            </p>
          </li>
        </ul>
        <div className='map'>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3163.429812476849!2d127.07718208850194!3d37.544936153296156!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca4d8638bbf7f%3A0x2afc163e219c8c60!2z7ISc7Jq47Yq567OE7IucIOq0keynhOq1rCDqtJHrgpjro6jroZwgNDc4!5e0!3m2!1sko!2skr!4v1728626671511!5m2!1sko!2skr"></iframe>
        </div>
      </div>
    </div>
  )
}

export default Conect;