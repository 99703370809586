import { Link } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';

function Experience() {
  return (
    <>
      <div className='ExperienceContainer'>
        <h3>Experience</h3>
        <div className='cubeInfo'>
          <ul>
            <li>
              <p className='infoImg'>
                <img src={process.env.PUBLIC_URL + "/img/Ex1.png"}></img>
              </p>
              <p className='infoTxt'>
                <span>문화 데이터</span>
                스페이셜코어는 기관, 기업의 수많은 문화 데이터를 광범위하고 빠르게 구축하고 연결해 왔습니다. 
문화 데이터는 다양한 분야와 연관되는 특성을 지닌 한편 비정형적 경향성이 높아 수집, 구축, 
관리의 충분한 경험이 필요합니다.
              </p>
            </li>
            <li>
              <p className='infoImg'>
                <img src={process.env.PUBLIC_URL + "/img/Ex2.png"}></img>
              </p>
              <p className='infoTxt'>
                <span>예술 데이터</span>
                국내 예술 데이터는 전반적으로 파편화되어 있습니다. 수집과 동질적 카테고리 구분이 어려운 예술 분야의 
데이터를 효율적으로 모으고 관계성을 부여할 수 
있도록 각 기관, 기업, 단체의 컨설팅 해 왔습니다.
              </p>
            </li>
            <li>
              <p className='infoImg'>
                <img src={process.env.PUBLIC_URL + "/img/Ex3.png"}></img>
              </p>
              <p className='infoTxt'>
                <span>관광 데이터</span>
                애프터 코로나 시대를 준비하는 관광활성화의 
중심에도 데이터 기반 의사결정이 매우 중요합니다, 한국관광공사와 공공기관, 지자체 자문을 해오며 
스마트관광거리 데이터 설계, 한류데이터 구축 등 
다양한 인/아웃바운드 사업에서 고품질의 
데이터를 만들 수 있도록 노력합니다.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default Experience;